import dayjs from 'dayjs';
import React from 'react';

import { ReturnToOfferCard } from './ReturnToOfferCard';
import { useUserPreferences } from '@Contexts/UserPreferencesContext/UserPreferencesContext';
import { useIsClient } from '@Core/useIsClient';

export const ReturnToOffer: React.FC = () => {
  const { returnToOffer } = useUserPreferences();
  const { key } = useIsClient();
  const offer = typeof window !== 'undefined' && returnToOffer.get();

  // Need to check `hotelName` to avoid rendering the component with the old data format
  if (!offer || !offer.hotelName) {
    return null;
  }

  // Need to check `departureDate` to avoid rendering the component with offer departure date in the past
  if (dayjs(offer.departureDate).isBefore(dayjs(), 'day')) {
    returnToOffer.set(null);

    return null;
  }

  return (
    <ReturnToOfferCard
      key={key} // to force hydration on the client
      {...offer}
    />
  );
};
